import React from "react";

const TextBlock = ({ text }) => {
  return (
    <div className="mb-5 text-xl font-light">
      <div className="mb-5 max-w-lg">{text}</div>
    </div>
  );
};

export default TextBlock;
