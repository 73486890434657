import React from "react";

// Components
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SocialLink from "../../components/SocialLink/SocialLink";
import CustomButton from "../../components/CustomButtom/CustomButton";

// Assets
import myAvatar from "../../assets/myAvatar.svg";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { GiHand } from "@react-icons/all-files/gi/GiHand";

const About = () => {
  return (
    <>
      <div className="bg-top w-full h-96 lg:h-64 2xl:h-96 bg-no-repeat bg-cover -mb-1" />
      <div className="bg-black">
        <section id="about" className="scroll-mt-32">
          <SectionTitle title="About Me" />
          <div className="mt-32 flex gap-6 max-w-5xl mx-auto text-white px-2 flex-wrap md:flex-nowrap">
            <div className="mx-auto">
              <div>
                <img src={myAvatar} alt="avatar" className="max-w-xs" />
              </div>
              <div className="w-3/5 mx-auto mt-14 customBtn transition-colors">
                <a
                  href="#contact"
                  className="text-gray-800 bg-white hover:bg-zinc-800 hover:text-white btn rounded-none border-0 relative z-1 w-full"
                >
                  <CustomButton strokeColor={"#ffffff"} />
                  Contact Me
                </a>
              </div>
              <div className="w-4/5 mx-auto mt-14 flex justify-around">
                <SocialLink
                  icon={<FaLinkedin />}
                  name="LinkedIn"
                  link="https://linkedin.com/in/gergoleventeputics"
                />
                <SocialLink
                  icon={<AiFillGithub />}
                  name="GitHub"
                  link="https://github.com/GPutics"
                />
              </div>
            </div>

            <div>
              <h3 className="text-3xl font-bold mb-5">
                <p className="flex">
                  <span className="pr-5">Hi there!</span>{" "}
                  <GiHand style={{ color: "rgb(227, 157, 27)" }} />
                </p>
                <p>
                  My name is <span className="underline">Gergo</span>, nice to
                  meet you.{" "}
                </p>
              </h3>
              <div className="mb-5 text-xl font-light">
                <div className="mb-5 max-w-lg">
                  My journey with web development started when I was a kid.
                </div>

                <div className="mb-5 max-w-lg">
                  I got introduced to the world of HTML and CSS and I started
                  creating very simple websites with that little knowledge I
                  had. Although I enjoyed what I was doing, being a kid with
                  many interests I soon forgot about it.
                </div>

                <div className="mb-5 max-w-lg">
                  After several years later while trying to find out what I
                  really wanted to do in life, I rediscovered web development
                  and it has been my passion and career goal ever since.
                </div>

                <div className="mb-5 max-w-lg">
                  Have a look at my{" "}
                  <a href="#skills" className="link hover:text-gray-300">
                    skills
                  </a>{" "}
                  or check out some of my recent
                  <a href="#projects" className="link hover:text-gray-300">
                    {" "}
                    projects.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-bottom w-full h-96 lg:h-64 2xl:h-96 bg-no-repeat bg-cover -mb-1" />
    </>
  );
};

export default About;
