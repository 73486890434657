import React from "react";

// Components
import EmailOptions from "../../components/EmailOptions/EmailOptions";

// Assets
import heroImage from "../../assets/heroImage.webp";
import { GoLocation } from "@react-icons/all-files/go/GoLocation";

const Hero = () => {
  return (
    <div className="hero grid place-items-center w-full">
      <div
        className="hero__content flex justify-center max-w-7xl gap-4 p-4 scroll-mt-20"
        id="hero"
      >
        <div>
          <img
            src={heroImage}
            alt="hero image"
            className="hidden lg:block lg:max-w-xs xl:max-w-md rounded-full mt-10"
          />
        </div>
        <div>
          <p className="mb-5 flex">
            <GoLocation className="mr-1" />
            <span>Munich, Germany</span>
          </p>
          <h1 className="mb-5 font-bold">
            <span className="text-3xl md:text-4xl lg:text-5xl inline-block subpixel-antialiased">
              Gergo Putics,
            </span>
            <br />
            <span className="text-6xl md:text-8xl lg:text-9xl inline-block subpixel-antialiased">
              front-end
            </span>
            <br />
            <span className="pb-4 text-6xl md:text-8xl lg:text-9xl inline-block subpixel-antialiased">
              developer
            </span>
          </h1>
          <p className="my-8 md:my-16 text-neutral xl:leading-loose text-xl">
            I'm a Front-end Developer currently based in Munich, Germany. I
            learned to code and design websites on my own, while doing so I have
            developed a great passion for beautifully crafted websites.
          </p>
          <EmailOptions
            strokeColor={"#333333"}
            baseBtnText={"Send me a message"}
            textColor={"text-gray-800 bg-transparent hover:bg-transparent"}
            secondaryTextColor={"text-gray-800"}
            hoverColor={"hover:bg-zinc-100"}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
