import React from "react";

// Components
import SocialLink from "../../components/SocialLink/SocialLink";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import EmailOptions from "../../components/EmailOptions/EmailOptions";

// Assets
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { GoLocation } from "@react-icons/all-files/go/GoLocation";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <div className="bg-top w-full h-96 lg:h-64 2xl:h-96 bg-no-repeat bg-cover -mb-1" />
      <div className="bg-black text-white ">
        <footer className="flex flex-wrap justify-evenly gap-5 lg:gap-10 max-w-5xl m-auto">
          <nav>
            <ul className="flex flex-col items-start">
              <li>
                <a
                  href="#about"
                  className="btn bg-transparent rounded-none border-0 w-full mb-2"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#projects"
                  className="btn bg-transparent rounded-none border-0 w-full mb-2"
                >
                  Projects
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="btn bg-transparent rounded-none border-0 w-full mb-2"
                >
                  Contact
                </a>
              </li>
              <li className="btn bg-transparent rounded-none border-0">
                <ScrollToTop style={{ fontSize: "2rem" }} />
              </li>
            </ul>
          </nav>
          <div className="text-left flex justify-center items-start z-0 max-w-7xl p-4 gap-4">
            <div className="max-w-2xl">
              <p className="mb-5 flex">
                <GoLocation className="mr-1" />
                <span>Munich, Germany</span>
              </p>
              <h2 className="mb-5 font-bold">
                <span className="block text-md lg:text-xl">Gergo Putics,</span>
                <span className="text-3xl lg:text-5xl subpixel-antialiased">
                  front-end <br />
                  developer
                </span>
              </h2>
              <EmailOptions
                strokeColor={"#ffffff"}
                baseBtnText={"Send me a message"}
                textColor={"bg-transparent hover:bg-transparent"}
                secondaryTextColor={"text-gray-800"}
                hoverColor={
                  "hover:bg-zinc-800 bg-white text-gray-800 hover:text-white"
                }
              />
              <div className="mt-14 flex justify-between">
                <SocialLink
                  icon={<FaLinkedin />}
                  name="LinkedIn"
                  link="https://linkedin.com/in/gergoleventeputics"
                />
                <SocialLink
                  icon={<AiFillGithub />}
                  name="GitHub"
                  link="https://github.com/GPutics"
                />
              </div>
              <p className="text-xs mt-5">Gergo Levente Putics &copy; {year}</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
