// Icons
import { AiFillHtml5 } from "@react-icons/all-files/ai/AiFillHtml5";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";

import { DiSass } from "@react-icons/all-files/di/DiSass";
import { DiJavascript } from "@react-icons/all-files/di/DiJavascript";
import { DiJavascript1 } from "@react-icons/all-files/di/DiJavascript1";
import { DiMongodb } from "@react-icons/all-files/di/DiMongodb";
import { DiReact } from "@react-icons/all-files/di/DiReact";

import { SiStyledComponents } from "@react-icons/all-files/si/SiStyledComponents";
import { SiNetlify } from "@react-icons/all-files/si/SiNetlify";
import { SiMaterialUi } from "@react-icons/all-files/si/SiMaterialUi";
import { SiReact } from "@react-icons/all-files/si/SiReact";
import { SiNextDotJs } from "@react-icons/all-files/si/SiNextDotJs";
import { SiTailwindcss } from "@react-icons/all-files/si/SiTailwindcss";

import { FaNodeJs } from "@react-icons/all-files/fa/FaNodeJs";
import { FiFigma } from "@react-icons/all-files/fi/FiFigma";
import { GrGithub } from "@react-icons/all-files/gr/GrGithub";
import { MdStorage } from "@react-icons/all-files/md/MdStorage";

// Assets
import movieDB from "./assets/moviedb.webp";
import exerciseTracker from "./assets/exercisetracker.webp";

export const skills = {
  frontEnd: [
    {
      id: 1,
      type: "Front-end skills",
      name: "HTML",
      icon: <AiFillHtml5 style={{ color: "#e34c26" }} />,
      progress: "90",
    },
    {
      id: 2,
      type: "Front-end skills",
      name: "CSS / SASS",
      icon: <DiSass style={{ color: "#CD6799" }} />,
      progress: "90",
    },
    {
      id: 3,
      type: "Front-end skills",
      name: "Tailwind.css",
      icon: <SiTailwindcss style={{ color: "#38bdf8" }} />,
      progress: "85",
    },
    {
      id: 4,
      type: "Front-end skills",
      name: "JavaScript",
      icon: <DiJavascript style={{ color: "#f7df1e" }} />,
      progress: "70",
    },
    {
      id: 5,
      type: "Front-end skills",
      name: "React",
      icon: <DiReact style={{ color: "#61DBFB" }} />,
      progress: "70",
    },
    {
      id: 6,
      type: "Front-end skills",
      name: "Next.js",
      icon: <SiNextDotJs />,
      progress: "60",
    },
  ],
  backEnd: [
    {
      type: "Back-end experience",
      id: 7,
      name: "Node",
      icon: <FaNodeJs style={{ color: "#68a063" }} />,
      progress: "50",
    },
    {
      type: "Back-end experience",
      id: 8,
      name: "Express",
      icon: <DiJavascript1 style={{ color: "#f7df1e" }} />,
      progress: "60",
    },
    {
      type: "Back-end experience",
      id: 9,
      name: "MongoDB",
      icon: <DiMongodb style={{ color: "#3FA037" }} />,
      progress: "60",
    },
  ],
  tools: [
    {
      type: "Tools I use",
      id: 11,
      name: "GitHub",
      icon: <GrGithub style={{ color: "#f34f29" }} />,
      progress: "100",
    },
    {
      type: "Tools I use",
      id: 13,
      name: "Figma",
      icon: <FiFigma />,
      progress: "100",
    },
    {
      type: "Tools I use",
      id: 14,
      name: "Netlify",
      icon: <SiNetlify style={{ color: "#00AD9F" }} />,
      progress: "100",
    },
  ],
};

export const projects = {
  project1: {
    id: 1,
    name: "Movies and TV Shows",
    img: `${movieDB}`,
    links: {
      websiteLink: "https://gergoputics-moviedb.netlify.app/",
      gitHubLink: "https://github.com/GPutics/MovieDBApp",
    },

    used: [
      {
        id: 1,
        name: "Html",
        icon: <AiFillHtml5 />,
      },
      {
        id: 2,
        name: "Styled Components",
        icon: <SiStyledComponents />,
      },
      {
        id: 3,
        name: "React",
        icon: <SiReact />,
      },
      {
        id: 4,
        name: "Material Ui",
        icon: <SiMaterialUi />,
      },
      {
        id: 5,
        name: "GitHub",
        icon: <AiFillGithub />,
      },
      {
        id: 6,
        name: "Netlify",
        icon: <SiNetlify />,
      },
    ],

    description: [
      {
        id: 1,
        content:
          'This project was my first "bigger" project I created. I had just finished an online React Js tutorial, and I acquired all the necessary knowledge to develop a more serious app.',
      },
      {
        id: 2,
        content:
          "I used Styled Components which was my first experience with the css-in-js styling technique. Even though it was strange at first, I got used to it quickly and I saw the advantages of this approach.",
      },
      {
        id: 3,
        content:
          "As I didn't have much experience with React at the time, it was a bit difficult to fully understand the concept of components, but as I was researching and learning on the go, I realized just how powerful and useful they are.",
      },
      {
        id: 4,
        content:
          "After I finished the project, I had a good grasp on what React Js has to offer: hooks, state management, props, custom hooks, routing with react router, etc...",
      },
    ],
  },

  project2: {
    id: 2,
    name: "Exercise Tracker App",
    img: `${exerciseTracker}`,
    links: {
      websiteLink: "https://gergoputics-exercise-tracker.netlify.app",
      gitHubLink: "https://github.com/GPutics/ExerciseTracker",
    },

    used: [
      {
        id: 1,
        name: "Html",
        icon: <AiFillHtml5 />,
      },
      {
        id: 2,
        name: "Sass",
        icon: <DiSass />,
      },
      {
        id: 3,
        name: "React",
        icon: <SiReact />,
      },
      {
        id: 4,
        name: "Local Storage",
        icon: <MdStorage />,
      },
      {
        id: 5,
        name: "GitHub",
        icon: <AiFillGithub />,
      },
      {
        id: 6,
        name: "Netlify",
        icon: <SiNetlify />,
      },
    ],

    description: [
      {
        id: 1,
        content:
          'My first project where I was entirely "on my own" (well, I had Google of course). The idea for the project came from personal need: an app where I can easily track my workouts.',
      },
      {
        id: 2,
        content:
          "There are quite a lot of input fields and information that needs to be passed around in the project, so once again React and its state management came to my help.",
      },
      {
        id: 3,
        content:
          "One of the most challenging aspects of the project was unexpected: styling. I used the table HTML tag for the exercise list, and the way I had the layout in mind, it made it difficult for the table to be responsive. Eventually I managed to get it to work on smaller screen sizes and I'm happy with how it looks, but I learned a valuable lesson: mobile first design approach is important.",
      },
      {
        id: 4,
        content:
          "The app is saving the exercises in the Local Storage so they wouldn't be lost once the browser is closed.",
      },
    ],
  },
};
