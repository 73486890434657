import React from "react";

// Components
import SkillCard from "./SkillCard";

// Data
import { skills } from "../../data";

// Assets
import { BsServer } from "@react-icons/all-files/bs/BsServer";
import { BsTools } from "@react-icons/all-files/bs/BsTools";
import { BiCodeBlock } from "@react-icons/all-files/bi/BiCodeBlock";

const Skills = () => {
  const { frontEnd, backEnd, tools } = skills;

  return (
    <div
      className="flex flex-wrap gap-10 justify-evenly max-w-6xl mx-auto -mt-24 mb-20 lg:mb-0 scroll-mt-52"
      id="skills"
    >
      <SkillCard
        title="Back-end knowledge"
        skills={backEnd}
        className="skills_box1"
        icon={<BsServer />}
      />
      <SkillCard
        title="Front-end skills"
        skills={frontEnd}
        className="skills_box2"
        icon={<BiCodeBlock />}
      />
      <SkillCard
        title="Tools I use"
        skills={tools}
        className="skills_box3"
        icon={<BsTools />}
      />
    </div>
  );
};

export default Skills;
