import React from "react";

// Components
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const RightMenu = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className={
        "flex justify-center fixed -top-full right-0 w-screen bg-gray-200 h-72 z-20 transition-all duration-500 " +
        (menuOpen && "menu-active")
      }
    >
      <ul className="flex flex-col justify-center m-0 p-6 font-light w-60">
        <li onClick={() => setMenuOpen(false)}>
          <a href="#about" className="btn w-full mb-5 btn-ghost rounded-none">
            About
          </a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
            href="#projects"
            className="btn w-full mb-5 btn-ghost rounded-none"
          >
            Projects
          </a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#contact" className="btn w-full mb-5 btn-ghost rounded-none">
            Contact
          </a>
        </li>
        <li></li>
      </ul>
      <span onClick={() => setMenuOpen(false)}>
        <ScrollToTop iconClass="absolute top-20 right-4 shadow-xl rounded-none text-gray-700" />
      </span>
    </div>
  );
};

export default RightMenu;
