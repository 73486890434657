import React from "react";

import CustomButton from "../CustomButtom/CustomButton";

const EmailOptions = ({
  strokeColor,
  textColor,
  baseBtnText,
  secondaryTextColor,
  hoverColor,
}) => {
  return (
    <div
      className={`${hoverColor} dropdown dropdown-hover customBtn transition-colors`}
    >
      <a
        href="mailto:puticsgl.business@gmail.com"
        target="_blank"
        rel="noreferrer"
        className={`${textColor} btn btn-md rounded-none border-0 text-inherit `}
      >
        <CustomButton strokeColor={strokeColor} />
        {baseBtnText}
      </a>
      <ul className="p-2 shadow bg-base-100 dropdown-content rounded-none w-full">
        <li>
          <a
            href="mailto:puticsgl.business@gmail.com"
            target="_blank"
            rel="noreferrer"
            className={`${secondaryTextColor} btn btn-ghost btn-sm lg:btn-md rounded-none w-full flex justify-start`}
          >
            Email
          </a>
        </li>
        <li>
          <a
            href="https://linkedin.com/in/gergoleventeputics"
            target="_blank"
            reL="noreferrer"
            className={`${secondaryTextColor} btn btn-ghost btn-sm lg:btn-md rounded-none w-full flex justify-start`}
          >
            LinkedIn
          </a>
        </li>
      </ul>
    </div>
  );
};

export default EmailOptions;
