import React from "react";

// Components
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ProjectsSlider from "../../components/ProjectsSlider/ProjectsSlider";
import ProjectLinksBlock from "../../components/ProjectLinksBlock/ProjectLinksBlock";
import ProjectTextBlock from "../../components/ProjectTextBlock/ProjectTextBlock";
import SectionSubtitle from "../../components/SectionSubtitle/SectionSubtitle";
import TextBlock from "../../components/TextBlock/TextBlock";
import Skill from "../../components/Skill/Skill";

// Data
import { projects } from "../../data";

const Projects = () => {
  const { project1, project2 } = projects;
  return (
    <>
      <div className="bg-top w-full h-96 lg:h-64 2xl:h-96 bg-no-repeat bg-cover -mb-1" />

      <div className="bg-black">
        <section id="projects" className="scroll-mt-32">
          <SectionTitle title="Projects" />
          <div className="mt-32 flex flex-wrap-reverse gap-6 max-w-5xl mx-auto text-white px-2">
            <ProjectLinksBlock
              linkWebsite={project1.links.websiteLink}
              linkGithub={project1.links.gitHubLink}
              icon={project1.img}
            />

            <ProjectTextBlock>
              <SectionSubtitle title={project1.name} />
              {project1.description.map((item) => (
                <TextBlock text={item.content} key={item.id} />
              ))}
              <SectionSubtitle title="Technologies used in the project" />
              <div className="grid grid-cols-5 gap-4">
                {project1.used.map((skill) => (
                  <Skill
                    skillName={skill.name}
                    skillIcon={skill.icon}
                    key={skill.id}
                  />
                ))}
              </div>
            </ProjectTextBlock>
          </div>

          <div className="mt-32 flex flex-wrap gap-6 max-w-5xl mx-auto px-2 text-white">
            <ProjectTextBlock>
              <SectionSubtitle title={project2.name} />
              {project2.description.map((item) => (
                <TextBlock text={item.content} key={item.id} />
              ))}
              <SectionSubtitle title="Technologies used in the project" />
              <div className="grid grid-cols-5 gap-4">
                {project2.used.map((skill) => (
                  <Skill
                    skillName={skill.name}
                    skillIcon={skill.icon}
                    key={skill.id}
                  />
                ))}
              </div>
            </ProjectTextBlock>

            <ProjectLinksBlock
              linkWebsite={project2.links.websiteLink}
              linkGithub={project2.links.gitHubLink}
              icon={project2.img}
            />
          </div>
          {/* Slider begins here */}
          <div className="mt-32 flex flex-wrap-reverse gap-6 max-w-5xl mx-auto text-white px-2">
            <div className="w-full md:w-9/12 mx-auto lg:ml-auto lg:mr-0 lg:max-w-xl">
              <h3 className="text-3xl font-bold text-right mb-5">
                More projects
              </h3>
              <p className="mb-5 text-right">
                Have a look at some other projects I created.
              </p>
              <div className="mb-5 text-xl font-light">
                <ProjectsSlider />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-bottom w-full h-96 lg:h-64 2xl:h-96 bg-no-repeat bg-cover -mb-1" />
    </>
  );
};

export default Projects;
