import React from 'react';

// Styling
import './SkillCard.css';

const SkillCard = ({title, skills, className, icon}) => {
  return (
    <div className={`${className} card shadow-2xl max-w-xs bg-white hover:scale-105 transition-transform`}>
      <div className="w-full h-36 bg-gray-200 flex flex-col items-center justify-around neutral px-5">
          <span className='text-5xl'>{icon}</span>
          <p className='font-bold'>{title}</p>
      </div>
      <div className="card-body text-left justify-between">
          {skills.map((item) => (
            <div key={item.id}>
              <p className='flex justify-between mb-1 items-center'>
                {item.name}
                <span className='text-4xl'>{item.icon}</span>
              </p>
              <progress className='progress w-56' value={item.progress} max='100'></progress>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SkillCard