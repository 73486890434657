import React from "react";

// Components
import CustomButton from "../CustomButtom/CustomButton";

const ProjectLinksBlock = ({ linkWebsite, linkGithub, icon }) => {
  return (
    <div className="max-w-sm justify-self-end mx-auto">
      <div className="w-full mx-auto lg:mx-0 lg:ml-auto pt-14">
        <div className="customBtn transition-colors mb-5">
          <a
            href={linkWebsite}
            target="_blank"
            rel="noreferrer"
            className="text-gray-800 bg-white hover:bg-zinc-800 hover:text-white btn rounded-none border-0 relative z-1 w-full"
          >
            <CustomButton strokeColor={"#ffffff"} />
            Live website
          </a>
        </div>
        <div className="customBtn transition-colors mb-5">
          <a
            href={linkGithub}
            target="_blank"
            rel="noreferrer"
            className="text-gray-800 bg-white hover:bg-zinc-800 hover:text-white btn rounded-none border-0 relative z-1 w-full"
          >
            <CustomButton strokeColor={"#ffffff"} />
            GitHub
          </a>
        </div>
      </div>
      <img
        src={icon}
        alt=""
        className="hidden lg:block h-40 opacity-70 w-full hover:scale-150 hover:opacity-90 transition-transform"
      />
    </div>
  );
};

export default ProjectLinksBlock;
