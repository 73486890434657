import React from "react";

// Assets
import socialMediaApp from "../../assets/social-media-app.webp";
import easybank from "../../assets/easybank.webp";
import coviddashboard from "../../assets/coviddashboard.webp";
import spacetourism from "../../assets/spacetourism.webp";

// Styling
import "./ProjectsSlider.css";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const otherProjects = [
  "Easybank landing page",
  "Space tourism website",
  "Covid-19 dashboard",
  "Social media app",
];

const ProjectsSlider = () => {
  const settings = {
    customPaging: function (i) {
      return (
        <li className="test opacity-50 hover:opacity-100 transition-opacity">
          <button></button>
          <p className="text-md whitespace-nowrap font-normal hidden lg:block">
            {otherProjects[i]}
          </p>
        </li>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        <a
          href="https://github.com/GPutics/EasyBank-FrontendMentor"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={easybank}
            alt="easybank landing page"
            className="max-h-xl opacity-70 w-full hover:opacity-90 transition-opacity"
          />
        </a>
        <a
          href="https://github.com/GPutics/SpaceTourism-FrontEndMentor"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={spacetourism}
            alt=" space tourism website"
            className="max-h-xl opacity-70 w-full hover:opacity-90 transition-opacity"
          />
        </a>
        <a
          href="https://github.com/GPutics/Covid19-Dashboard"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={coviddashboard}
            alt="covid-19 dashboard"
            className="max-h-xl opacity-70 w-full hover:opacity-90 transition-opacity"
          />
        </a>
        <a
          href="https://gergoputics-socialmedia.netlify.app"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={socialMediaApp}
            alt="social media app"
            className="max-h-xl opacity-70 w-full hover:opacity-90 transition-opacity"
          />
        </a>
      </Slider>
    </div>
  );
};

export default ProjectsSlider;
