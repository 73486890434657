import React from "react";

const CustomButton = ({ strokeColor }) => {
  return (
    <svg>
      <rect
        x="0"
        y="0"
        fill=""
        width="100%"
        height="100%"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default CustomButton;
