import React from "react";

// Components
import { Squeeze as Hamburger } from "hamburger-react";

// Assets
import logo from "../../assets/Logo_v2.svg";

const Navbar = ({ menuOpen, setMenuOpen }) => {
  return (
    <header>
      <div
        className="fixed z-30 top-4 right-4 bg-gray-200 rounded-none md:hidden shadow-md"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <Hamburger toggle={setMenuOpen} toggled={menuOpen} />
      </div>

      <div className="navbar p-2 fixed mx-auto w-full z-10 bg-gray-50 hidden md:block">
        <nav className="flex justify-between items-center mx-auto max-w-7xl">
          <a href="#hero">
            <img src={logo} alt="logo" style={{ width: "100px" }} />
          </a>
          <ul className="flex md:mr-2">
            <li className="btn btn-ghost rounded-none">
              <a href="#about">About</a>
            </li>
            <li className="btn btn-ghost rounded-none">
              <a href="#projects">Projects</a>
            </li>
            <li className="btn btn-ghost rounded-none">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
