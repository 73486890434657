import React from "react";

const SectionTitle = ({ className, title }) => {
  return (
    <>
      <div className="text-white">
        <h2
          className={`${className} text-3xl md:text-5xl font-bold text-center subpixel-antialiased mx-auto p-2 w-max`}
        >
          {title}
        </h2>
      </div>
    </>
  );
};

export default SectionTitle;
