import React, { useState } from "react";

// Components
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import CustomButton from "../../components/CustomButtom/CustomButton";

// Copy to clipboard
import copy from "copy-to-clipboard";

// Assets
import { GoMegaphone } from "@react-icons/all-files/go/GoMegaphone";

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");

  const clickHandler = (e) => {
    copy("puticsgl.business@gmail.com");
    setSuccessMessage("tooltip tooltip-open tooltip-accent");
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
  };

  return (
    <div className="bg-white">
      <section id="contact" className="pb-20 ">
        <SectionTitle title="Contact Me" className="pt-20 text-gray-800" />

        <div className="flex flex-col gap-10 max-w-2xl mx-auto px-2">
          <h3 className="text-2xl font-bold mb-5 tex-white mt-32">
            Interested in working together?
            <br />
            Let's get in touch!
          </h3>
          <GoMegaphone className="text-9xl w-full" />
          <div className="flex flex-wrap-reverse justify-between">
            <div className="dropdown dropdown-hover customBtn hover:bg-zinc-100 transition-colors mb-5 sm:mb-0">
              <a
                href="mailto:puticsgl.business@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-gray-800 btn btn-wide rounded-none bg-transparent hover:bg-transparent border-0"
              >
                <CustomButton strokeColor={"#333333"} />
                Send me an email
              </a>
              <ul className="p-2 shadow dropdown-content bg-base-100 rounded-none">
                <li>
                  <a
                    href="mailto:puticsgl.business@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-ghost btn-sm lg:btn-md rounded-none"
                  >
                    Open in email client
                  </a>
                </li>
                <li>
                  <button
                    className="btn btn-ghost btn-sm lg:btn-md rounded-none"
                    onClick={clickHandler}
                  >
                    <span
                      data-tip="Copied to clipboard!"
                      className={successMessage}
                    >
                      Copy email address
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="dropdown customBtn hover:bg-zinc-100 transition-colors mb-5 sm:mb-0">
              <a
                href="https://linkedin.com/in/gergoleventeputics"
                target="_blank"
                rel="noreferrer"
                className="text-gray-800 btn btn-wide rounded-none bg-transparent hover:bg-transparent border-0"
              >
                <CustomButton strokeColor={"#333333"} />
                Message me on LinkedIn
              </a>
            </div>
          </div>
          <h3 className="text-2xl font-bold tex-white">
            ... or use the form below
          </h3>
          <form
            name="contact-v1"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit="submit"
          >
            <input type="hidden" name="form-name" value="contact-v1" />
            <div hidden>
              <input name="bot-field" />
            </div>

            <div className="p-10 card bg-white input-card">
              <div className="flex flex-col">
                <label htmlFor="name" className="label label-text">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="input input-bordered"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="label label-text">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className="input input-bordered"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="message" className="label label-text">
                  Your Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  required
                  className="textarea h-24 textarea-bordered"
                ></textarea>
              </div>
              <input
                type="submit"
                className="btn w-max mt-10 mx-auto border-2 rounded-none bg-transparent text-gray-800 hover:text-white"
                value="Send message"
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
