import React from "react";

const SocialLink = ({ icon, name, link }) => {
  return (
    <div>
      <a href={link} target="_blank" rel="noreferrer">
        <span className="text-5xl hover:text-gray-300">{icon}</span>
      </a>
      <p className="text-center text-xs mt-1">{name}</p>
    </div>
  );
};

export default SocialLink;
