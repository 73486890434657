import React from "react";

const SectionSubtitle = ({ title }) => {
  return (
    <>
      <h3 className="text-3xl font-bold mb-5">{title}</h3>
    </>
  );
};

export default SectionSubtitle;
