import React from "react";

const Skill = ({ skillName, skillIcon }) => {
  return (
    <div className="flex flex-col items-center">
      <span className="text-4xl">{skillIcon}</span>
      <span className="text-sm text-center">{skillName}</span>
    </div>
  );
};

export default Skill;
